<template>
	<div class="d-flex flex-column flex-root" v-if="isAuthenticated">
		<Loader v-if="loaderEnabled" v-bind:logo="loaderLogo" :spinnerClass="'spinner-light'"></Loader>
		<div class="d-flex flex-row flex-column-fluid page">
			<div id="kt_wrapper" class="d-flex flex-column flex-row-fluid wrapper">
				<MinimalHeader></MinimalHeader>
				<div id="kt_content" class="content d-flex flex-column flex-column-fluid">
					<div class=" d-flex px-10 my-5">
						<h4 class="text-dark font-weight-bold my-2 mr-5">
							{{ pageTitle }}
						</h4>
					</div>
					<div class="d-flex flex-column-fluid">
						<div class="container-fluid">
							<transition name="fade-in-up">
								<router-view />
							</transition>
						</div>
					</div>
				</div>
				<KTFooter></KTFooter>
			</div>
		</div>
		<KTScrollTop></KTScrollTop>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import MinimalHeader from '@/view/layout/header/MinimalHeader.vue';
import KTHeaderMobile from '@/view/layout/header/HeaderMobile.vue';
import KTFooter from '@/view/layout/footer/Footer.vue';
import HtmlClass from '@/core/services/htmlclass.service';
import KTScrollTop from '@/view/layout/extras/ScrollTop';
import Loader from '@/view/content/loaders/Loader.vue';
import { ADD_BODY_CLASSNAME, REMOVE_BODY_CLASSNAME } from '@/core/services/store/htmlclass.module.js';

export default {
	name: 'Layout',
	components: {
		MinimalHeader,
		KTHeaderMobile,
		KTFooter,
		KTScrollTop,
		Loader,
	},
	beforeMount() {
		// show page loading
		this.$store.dispatch(ADD_BODY_CLASSNAME, 'page-loading');

		// initialize html element classes
		HtmlClass.init(this.layoutConfig());
	},
	mounted() {
		setTimeout(() => {
			this.$store.dispatch(REMOVE_BODY_CLASSNAME, 'page-loading');
		}, 2000);
	},
	methods: {},
	computed: {
		...mapGetters(['isAuthenticated', 'breadcrumbs', 'pageTitle', 'layoutConfig']),

		loaderEnabled() {
			return !/false/.test(this.layoutConfig('loader.type'));
		},

		loaderLogo() {
			return process.env.BASE_URL + this.layoutConfig('loader.logo');
		},

		asideEnabled() {
			return !!this.layoutConfig('aside.self.display');
		},

		toolbarDisplay() {
			// return !!this.layoutConfig("toolbar.display");
			return true;
		},

		subheaderDisplay() {
			return !!this.layoutConfig('subheader.display');
		},
	},
};
</script>
